/**
 * Events calendar
 */
import * as qs from 'qs';

import { scrollToElementIfNecessary, trapTabs } from "./utilities";

function toggleFiltersMenu({ target }) {
    const filtersMenu = document.querySelector('.events-filters-menu');

    if (target.matches('.events-filters-toggle, .events-filters-toggle-text, .events-filters-close button, .events-filters-x, .events-filters-x *')) {
        document.querySelector('.events-filters-toggle').classList.toggle('open');
        filtersMenu.classList.toggle('open');
        document.querySelector('body').classList.toggle('events-menu-open');

        if (window.isSmall) {
            trapTabs(filtersMenu);
        }

        return;
    }

    // close filter menu when clicking outside of it
    const filtersToggle = document.querySelector('.events-filters-toggle');

    if (
        !filtersToggle?.matches('.open') ||
        target.matches('.events-filters-toggle') ||
        target.matches('.events-filters-menu') ||
        target.matches('.events-filters-menu *')
    ) {
        return;
    }

    filtersToggle.classList.remove('open');
    filtersMenu.classList.remove('open');
    document.querySelector('body').classList.remove('events-menu-open');
};

function filterToggleButtons(event) {
    const { target, code } = event;

    if (target.matches('.events-toggle') && ['Enter', 'Space'].includes(code)) {
        event.preventDefault();
        target.click();
    }
}

function handleToggleButtonClick({ target }) {
    if (!target.matches('.events-toggle__mobile')) return;

    // The toggle buttons on mobile are not actual form elements, so make sure that the corresponding input is toggled
    target.classList.toggle('active');
    const toggleInput = document.querySelector(`.events-toggle input[value="${ target.dataset.tagValue }"]`);
    toggleInput.checked = !toggleInput.checked;

    document.querySelector('form.events-form').requestSubmit();
    updateFilterMenu();
}

function updateFilterMenu() {
    // Make sure the corresponding tag toggle buttons on desktop and mobile have the same state
    document.querySelectorAll('.events-toggle__mobile').forEach((element) => {
        const { tagValue } = element.dataset;
        const toggleInput = document.querySelector(`.events-toggle input[value="${ tagValue }"]`);

        if (toggleInput.checked) {
            element.classList.add('active');
        } else {
            element.classList.remove('active');
        }
    });

    // Check the All filters checkbox if no filters are selected
    const allFiltersButton = document.querySelector('.events-checkbox--all input');

    if (!allFiltersButton) return;

    allFiltersButton.checked = !document.querySelectorAll('.events-toggle input:checked, .events-toggle.active, .events-filters-menu label:not(.events-checkbox--all) input:checked').length;

    // Count the number of selected filters and indicate it
    const selectedFilterMenuItems = document.querySelectorAll('.events-filters-menu label:not(.events-checkbox--all) input:checked').length;
    const filterButtonText = document.querySelector('.events-filters-toggle-text');
    const updatedText = selectedFilterMenuItems ? `Filters (${ selectedFilterMenuItems })` : 'Filters';

    filterButtonText.innerHTML = updatedText

    console.log('selectedFilterMenuItems', selectedFilterMenuItems);
}

function setupTodayExpandable() {
    const todaySection = document.querySelector('.events-today');

    if (!todaySection) return;

    const itemCount = todaySection.querySelectorAll('li').length;
    const moreButton = todaySection.querySelector('.events-today__more');

    if ((window.isSmall && itemCount > 2) || (!window.isSmall && itemCount > 4)) {
        moreButton.style.display = 'block';
    } else {
        moreButton.style.display = 'none';
    }

    document.addEventListener('click', ({ target }) => {
        if (!target.matches('.events-today__more')) return;

        document.querySelector('#today-events-list').classList.add('active');
        target.remove();
    });
}

function handleFilterClear(event) {
    const { target } = event;

    if (!target.matches('.events-checkbox--all') && !target.closest('.events-checkbox--all')) return;

    event.preventDefault();

    const allCheckbox = target.matches('input') ? target : target.querySelector('input');
    allCheckbox.checked = true;

    document.querySelectorAll('.events-toggle input[type="checkbox"], .events-toggle.active, .events-filters-menu ul label.checkbox:not(.events-checkbox--all) input').forEach((element) => {
        element.checked = false;

        const toggleLabel = element.closest('.events-toggle');
        if (toggleLabel || element.matches('.event-toggle__mobile')) {
            toggleLabel.classList.remove('active');
        }
    });

    target.closest('form.events-form').dispatchEvent(new Event('change'));
}

function handleFormChange({ target }) {
    updateFilterMenu();
    target.closest('form.events-form').requestSubmit();
}

// Add the form values to the browser URL so that the user can refresh the page and see the same state
function handleTurboSubmit({ target }) {
    const formData = new FormData(target);
    const fields = {};
    formData.forEach((value, name) => {
        if (!value) return;

        // Handle param arrays so that Rails can parse them, e.g. tags[]
        if (name.endsWith('[]')) {
            const fieldName = name.replace(/\[\]$/, '');
            fields[fieldName] ||= [];
            fields[fieldName].push(value);
        } else {
            fields[name] = value;
        }
    });

    const params = qs.stringify(fields, { arrayFormat: 'brackets', encode: false });
    const queryPrefix = params ? '?' : '';
    const url = [
        window.location.pathname,
        queryPrefix,
        params,
    ].join('');

    window.history.pushState(window.history.state, document.title, url);
}

function handleTurboSubmitEnd({ target }) {
    scrollToElementIfNecessary(target);
}

export default function setupCalendar() {
    document.addEventListener('click', toggleFiltersMenu);
    setupTodayExpandable();
    document.addEventListener('keydown', filterToggleButtons);
    document.addEventListener('click', handleToggleButtonClick);
    document.addEventListener('click', handleFilterClear);
    document.querySelector('.events-form')?.addEventListener('change', handleFormChange);
    document.addEventListener('turbo:submit-start', handleTurboSubmit);
    document.addEventListener('turbo:submit-end', handleTurboSubmitEnd);
    updateFilterMenu();
}